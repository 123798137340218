
export const THUNDER = [29.266666666666666, 
    32.86666666666667, 
    36.53333333333333, 
    40.06666666666667, 
    43.7, 
    47.3, 
    50.86666666666667, 
    54.5, 
    58.1, 
    61.666666666666664, 
    70.73333333333333, 
    77.9, 
    85.1, 
    92.3, 
    100.4, 
    111.43333333333334, 
    161.93333333333334, 
    165.56666666666666, 
    169.26666666666668, 
    172.83333333333334, 
    222.9, 
    226.4, 
    229.96666666666667, 
    233.66666666666666, 
    251.26666666666668, 
    254.76666666666668, 
    257.23333333333335, 
    258.3666666666667, 
    262.0, 
    265.3666666666667, 
    268.93333333333334, 
    272.4, 
    275.93333333333334, 
    279.1666666666667];

export const OFFSET = 0.1;

export const MAX_NAME_LENGTH = 24;


